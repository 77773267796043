import React, { useRef, useState } from 'react';
import {Button, Form, Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'

function Applications () {
    const form = useRef()
    const [show, setShow] = useState(undefined);
    const onChange = () => {
      document.getElementById('send-button-application').disabled = false;
    }
    const sendEmail = (e) => {
      e.preventDefault();
        emailjs.sendForm
        (
            'service_hcmp9fh',
            'template_wkjiy9t',
          e.target,
            'W19rw2dUHNtpDrqzL'
        )
        .then(()=>
            {
              setShow({type: 'success'})
              e.target.reset()
            })
        .catch(error=>
          setShow({type:  'error', error})
        )
    }
    return (
      <div className='container'>
            <h2 className='mt-3'>Work With Us</h2>
            <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Name</Form.Label>
                <Form.Control type='text' placeholder='name' name='name' controlId='name' required/><br/>
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' placeholder='enter email' name='email' controlId='email' required/><br/>
                
                <Form.Label>Months of experience</Form.Label>
                <Form.Control type='text' placeholder='months of experience' name='experience' controlId='experience' required/><br/>

                <Form.Label>Address</Form.Label>
                <Form.Control type='text' placeholder='your address' name='address' controlId='address' required/><br/>
                
                
                <Form.Label>Tell us a bit about yourself</Form.Label>
                <Form.Control as='textarea' rows={3} type='yourself' placeholder='about yourself' name='yourself' controlId='yourself' required /><br/>
                </Form.Group>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITEKEY} onChange={onChange} className='mb-3 '/>
                <Button id='send-button-application' variant='primary' type='submit' value='send' className='bg-energyyellow' disabled>
                send
                </Button>
            </Form>

            <>
              {show?.type === 'success' && <Alert className='mt-2'>Your message has been sent successfully</Alert>}
              {show?.type === 'error' && <Alert className='mt-2'>Message sending failed. Please try again.</Alert>}
            </>


      </div>
    );
  };


export default Applications;
