import React, { useRef, useState } from 'react';
import {Button, Form, Alert} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'

function Mailer () {
    const form = useRef()
    const [show, setShow] = useState(undefined);
    const onChange = () => {
      document.getElementById('send-button').disabled = false;
    }
    const sendEmail = (e) => {
      e.preventDefault();
        emailjs.sendForm
        (
          'service_q8syno4',
          'template_9kug6ki',
          e.target,
          'W19rw2dUHNtpDrqzL'
        )
        .then(()=>
            {
              setShow({type: 'success'})
              e.target.reset()
            })
        .catch(error=>
          setShow({type:  'error', error})
        )
    }
    return (
      <div className='container'>
            <h2 className='mt-3'>Contact Us</h2>
            <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Name</Form.Label>
                <Form.Control type='text' placeholder='Name' name='user_name' controlId='formName' required/><br/>
                <Form.Label>Email</Form.Label>
                <Form.Control type='email' placeholder='Enter email' name='user_email' controlId='formUser_email' required/><br/>
                <Form.Label>Message</Form.Label>
                <Form.Control as='textarea' rows={3} type='message' placeholder='message' name='message' controlId='formMessage' required /><br/>
                </Form.Group>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITEKEY} onChange={onChange} className='mb-3'/>
                <Button id='send-button' variant='primary' type='submit' value='send' className='bg-energyyellow' disabled>
                send
                </Button>
            </Form>

            <>
              {show?.type === 'success' && <Alert className='mt-2'>Your message has been sent successfully</Alert>}
              {show?.type === 'error' && <Alert className='mt-2'>Message sending failed. Please try again.</Alert>}
            </>


      </div>
    );
  };


export default Mailer;
