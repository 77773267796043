import {Container, Navbar, Nav, Row, Col, Image} from 'react-bootstrap';
import { H, Section } from 'react-headings';
import Mailer from './mailer.js';
import Applications from './applications.js';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-P5G35W4X8G');





const applications = {
  title:`Join the team at M8 Traffic Management!`,
  copy: `We're looking for safety-focused individuals ready to make a difference on our roads. With training, growth opportunities, and a supportive environment, M8 is the perfect place to start or advance your career in traffic management. Apply today and be part of a team committed to safety and excellence!`
}




const App = () =>(
    <div className='App'>
    <header>
      <Navbar fixed='top' collapseOnSelect expand='lg'>
        <Container>
          <Navbar.Brand href='https://trafficmanagementinmelbourne.com.au/'>
              <img
                src='logo-horizontal.svg'
                width='175'
                height='40'
                className='d-inline-block align-top'
                alt='M8 Traffic Management Melbourne'
              />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'>
              <Nav.Link href='#aboutus'>About Us</Nav.Link>
              <Nav.Link href='#ourservices'>Our Services</Nav.Link>
              <Nav.Link href='#contactus'>Contact Us</Nav.Link>
              <Nav.Link href='#applications'>Work With Us</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link eventKey={2} href='tel:+61401652236'>
                Call Us Today: 0401 652 236
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
    <main>
      <Container fluid className='bg-light cover d-flex justify-content-center align-items-center min-vh-100'>
          <Row className='pt-5 animate-opacity animate-bottom'>
            <Col className='text-center '>
            <Image
              src='logo-white.svg' className='rounded w-50 d-block mx-auto' alt='M8 Traffic Management Melbourne'
              />
            <Section component={<H className='pt-2 display-1 fw-bold'>M8 <span  className='text-energyyellow'>Traffic</span> Management</H>}>
              <Section component={<H className='pt-2'>TRAFFIC MANAGEMENT - EQUIPMENT HIRE - PLANNING</H>}>
                <Section component={<H className='pt-2'>Call Us Today: <a href='tel:+61401652236'  className='text-white'>0401 652 236</a></H>}> </Section>
                <Section component={<H className='pt-2'>We'll help with planning, permits, and project rollouts.</H>}> </Section>
              </Section>
            </Section>
            </Col>
          </Row>
      </Container>
      <Container>
         <Row id='aboutus' className='pt-5 animate-opacity animate-bottom'>
           <Col lg={6} className='py-5 text-center justify-content-center align-self-center'>
             <Image
             src='img02.webp'
             rounded
             className='w-75 img-thumbnail mb-2'
             alt='M8 Traffic Management Melbourne About Us'
             />
           </Col>
           <Col lg={6} className='pb-5 justify-content-center align-self-center text-start'>
             <h2 className='font-weigh-light'> ABOUT US </h2>
             <p>We are committed to ensuring both safety and efficiency in all projects. Prioritizing safety is crucial to avoid any accidents or mishaps that could harm people or damage property. And ensuring efficiency will ensure that projects are completed on time and within budget.</p>
             <h3> We prioritize safety in all aspects of your traffic management work.</h3>
           </Col>
         </Row>
     </Container>
     <Container fluid className='bg-energyyellow pt-5'>
       <Container>
          <Row className='pt6 text-start' id='ourservices'>
          <h2> OUR SERVICES </h2>
          </Row>
          <Row id='features' className='text-start'>
            <Col lg={3} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
             src='img03.webp'
              thumbnail
              className='mb-2'
              alt='M8 Traffic Management Melbourne Emergency Traffic Control'
              />
            <h2 className='pt-5'>EMERGENCY TRAFFIC CONTROL</h2>
            <p>Any type of emergency, our team is always ready to respond; this includes weather, vehicle, and other types of hazards. Call us and we will be happy to help.</p>
            </Col>
            <Col lg={3} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
             src='img04.webp'
              thumbnail
              className='mb-2'
              alt='M8 Traffic Management Melbourne Emergency Equipment Hire'
              />
            <h2 className='pt-5'>EQUIPMENT HIRE</h2>
            <p>We have a range of Traffic Control equipment ready for short term or long term use. We only use the best quality of products starting from Emergency Lighting, Bollards, Signage, Traffic Cones &amp; Frames.</p>
            </Col>
            <Col lg={3} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
             src='img05.webp'
              thumbnail
              className='mb-2'
              alt='M8 Traffic Management Melbourne Planning & Permits'
              />
            <h2 className='pt-5'>PLANNING & PERMITS</h2>
            <p>Our team is trained and well prepared to assist in your shortly timed projects like (VIC roads -owned/council-owned roads) or a larger scale project which requires more preparation and planning.</p>
            </Col>
            <Col lg={3} className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
              <Image
             src='img06.webp'
              thumbnail
              className='mb-2'
              alt='M8 Traffic Management Melbourne Event Management'
              />
            <h2 className='pt-5'>EVENT MANAGEMENT</h2>
            <p>Our event planners prioritize the safety and enjoyment of attendees at your events. Managing traffic flow and large crowds can be a challenging task, but with M8 Traffic Management we have you covered.</p>
            <p>We can set up clear and visible signage to direct traffic and pedestrians, setting up barriers or fencing to guide people towards entrances and exits, and having trained personnel on hand to manage traffic and respond to any emergencies that may arise. Call M8 for a quote on managing your next event.</p>
            </Col>
          </Row>
       </Container>
     </Container>

     <Container>
        <Row id='contactus' className='py-5 text-start'>
          <Col lg={6} className='animate-opacity animate-bottom my-3'>
          <Mailer />
          </Col>
          <Col lg={6} className='animate-opacity animate-bottom my-3'>
          <p>
          <b>Address:</b> 65 Newlands Road, Coburg North VIC 3058 <br/>
          <b>Phone:</b> <a href='tel:+61401652236'  className='text-white'>0401 652 236</a> <br/>
          <b>Email:</b> <a href='mailto:info@m8trafficmanagement.com.au'  className='text-white'>info@m8trafficmanagement.com.au</a>
          </p>
          <a href='https://www.linkedin.com/company/m8trafficmanagement/' target='_blank' rel='noopener noreferrer'>
          <Image src='linkedin.png' className='px-1' alt='M8 Traffic Management linkedin' /></a>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row id='applications' className='py-5 text-start'>
          <Col lg={6} className='animate-opacity animate-bottom my-3'>
            <Col className='my-5 gx-5 text-left justify-content-center align-self-top animate-opacity animate-bottom'>
                <Image
                src='img07.webp'
                thumbnail
                className='mb-2'
                alt='M8 Traffic Management Melbourne Emergency Equipment Hire'
                />
              <h2 className='pt-5'>{applications.title}</h2>
              <p>{applications.copy}</p>
              </Col>
          </Col>
          <Col lg={6} className='animate-opacity animate-bottom my-3'>
          <Applications />
          </Col>
        </Row>
      </Container>
    </main>
    </div>
)

export default App;
